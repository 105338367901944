import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'
$("header .nav li").eq(0).addClass("active");
$("header").addClass("head1");
$("header").removeClass("on");

var swiper = new Swiper(".index-banner .mySwiper", {
    observer:true,//修改swiper自己或子元素时，自动初始化swiper
    observeParents:true,//修改swiper的父元素时，自动初始化swiper
    loop:true,
    speed:2000,
    autoplay: {
        delay: 3000,
    },
    pagination: {
        el: '.index-banner .swiper-page',
        clickable: true,
		renderBullet: function (index, className) {
			return '<span class="' + className + '">' + "</span>";
		},
    },
});


$(".product-nav ul li").eq(0).addClass("active")
$(".product-list ul").eq(0).addClass("active")
$(".product-nav ul li").hover(function () {
    let index = $(this).index()
    $(this).addClass("active").siblings().removeClass("active");
    $(".product-list ul").eq(index).addClass("active").siblings().removeClass("active");
})

$(".section2a ul li").eq(0).addClass("active");
$(".section2a ul li").hover(function () {
    $(this).addClass("active").siblings().removeClass("active");
})